import React from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";

import PageWrapper from "../../components/PageWrapper";
import { Section, Title, Text, Box, Badge } from "../../components/Core";

import styled from "styled-components";

import titleImage from "../../assets/image/jpeg/taiwan.jpg";
import iconQuote from "../../assets/image/png/quote-icon.png";
import styles from '../../assets/styles/blog.module.css';


const Post = styled(Box)`
  overflow: hidden;
  font-size: 1rem;

  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  ul,
  ol {
    margin-bottom: 1.25rem;
    &:last-child {
      margin-bottom: 0;
    }
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 2.25rem;
    margin-bottom: 1.25rem;
    color: ${({ theme }) => theme.colors.heading} !important;
  }
  ol li {
    list-style-type: decimal;
  }

  ul li {
    list-style-type: disc;
  }

  blockquote {
    margin-bottom: 1.25rem;
    padding-left: 50px;
    position: relative;
    color: ${({ theme }) => theme.colors.text} !important;
    font-size: 20px;
    &::after {
      content: url(${iconQuote});
      display: inline-block;
      min-width: 28px;
      max-width: 28px;
      margin-top: 8px;
      margin-right: 23px;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  img,
  iframe,
  video {
    max-width: 100%;
    margin-bottom: 2rem;
    display: block;
  }
`;

const BadgePost = ({ children }) => (
    <Badge
        mr={3}
        mb={3}
        bg="#eae9f2"
        color="#696871"
        fontSize="16px"
        px={3}
        py={2}
    >
        {children}
    </Badge>
);


const BlogDetails = () => {
    return (
        <>
            <PageWrapper footerDark>
                <Section className="pb-0">
                    <div className="pt-5"></div>
                    <Container>
                        <Row className="justify-content-center text-center">
                            <Col lg="12">
                                <Title variant="hero">
                                    Shopping in Australia and forwarding to Taiwan
                  <br className="d-none d-lg-block" />
                                </Title>
                                <Box className="d-flex justify-content-center">
                                    <Text mr={3}>Feb 10, 2020
                  </Text>
                                    <Text>Michael Tippett
                  </Text>
                                </Box>
                            </Col>
                        </Row>
                    </Container>
                </Section>
                <Section className="pb-0 pt-4">
                    <Container>
                        <Row>
                            <Col lg="12" className="mb-5">
                                {/* <!-- Blog section --> */}
                                <Post>
                                    <div>
                                        <img src={titleImage} alt="Shopping From Taiwan" className={styles.titleImage} />
                                    </div>
                                    <div>

                                        <b>The best solution to get your orders delivered from Australia to Taiwan!</b>
                                        <p>Ever wondered how you can reduce your costs while ordering from Australia to Taiwan? Indeed there is a way, our amazing parcel forwarding service enables its users to majorly reduce their costs, especially when ordering in bulk! You have the ability to get your products parcelled to you for less!</p>
                                        <p>The process is simple, you get the product to our warehouse in Australia and we get the product to your doorstep in Taiwan. All this while ensuring that you get your package safe and steady. We have the highest standards of consolidation, packaging, and delivery.</p>
                                        <p>All this is done through Direct Mail, which ensures that there are major savings with your orders. Doesn’t matter if you are a professional international trader or just someone wanting to order a single product, we provide service to everyone around the globe!</p>
                                        <a href="https://members.hotsnail.com.au/signup">Sign up to HotSnail now</a>
                                    </div>
                                </Post>
                                <Box className="d-flex" mt={4}>
                                    <BadgePost>Taiwan</BadgePost>
                                    <BadgePost>Shopping</BadgePost>
                                    <BadgePost>Mail forwarding</BadgePost>

                                </Box>
                            </Col>
                        </Row>
                    </Container>
                </Section>
            </PageWrapper>
        </>
    );
};
export default BlogDetails;
